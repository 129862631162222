'use client';

import React, { ReactNode, forwardRef } from 'react';

import Balancer from 'react-wrap-balancer';
import styled, { css } from 'styled-components';

import * as Typography from 'styles/component-styles/typography';
import { spaces } from 'styles/constants';
import { Colors, FontFamily, FontWeight } from 'styles/enums';

enum HeaderElements {
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
}

enum BodyElements {
  'p',
  'span',
  'div',
  'label',
  'caption',
  'small',
  'strong',
  'figcaption',
}

type HeaderTypes = keyof typeof HeaderElements;

type BodyTypes = keyof typeof BodyElements;

export type TextVariants = 'default' | 'card' | 'footerLink' | 'xsmall' | 'smallparag' | 'cardparag' | 'articleparag' | 'link';

export type TextElements = HeaderTypes | BodyTypes;

export type VariantTypes = TextElements | TextVariants;

type TypographyListKey = `Headings-${keyof typeof Typography.headings}` | `Body-${keyof typeof Typography.body}`;

const makeList = <T, ListType extends string>(list: Record<keyof T, {}>) =>
  Object.keys(list).reduce((sum, key) => {
    const groupKey = key as keyof T;
    const group = list[groupKey];

    Object.keys(group).forEach((key) => {
      const listKey = key as keyof typeof group;
      const value = group[listKey];

      if (typeof value !== 'object') {
        sum[`${String(groupKey)}-${String(listKey)}` as ListType] = value as string;
      }
    });

    return sum;
  }, {} as Record<ListType, string>);

const TypographyList = makeList<typeof Typography, TypographyListKey>(Typography);

enum TextAligment {
  'center',
  'initial',
  'end',
}

interface BaseProps {
  id?: string;
  title?: string;
  children?: ReactNode;
  $variant?: VariantTypes;
  /**
   * Overwrite variant typography
   */
  $typography?: keyof typeof TypographyList;
  /**
   * Overwrite variant font weight
   */
  $fontWeight?: keyof typeof FontWeight;
  /**
   * Overwrite variant color
   */
  $color?: keyof typeof Colors.List;
  $paddingBottom?: 'paragraph' | 'h3';
  $paddingTop?: 'paragraph' | 'h3' | 'none';
  $noSpacing?: boolean;
  $dataAnchor?: boolean;
  className?: string;
  $noBalancer?: boolean;
  $textAlign?: keyof typeof TextAligment;
}

type ExtraProps =
  | {
      $truncateHorizontal?: boolean;
      $truncateVertical?: boolean;
      $hyphen?: never;
    }
  | {
      $truncateHorizontal?: never;
      $truncateVertical?: never;
      $hyphen?: boolean;
    };

export type TextProps = BaseProps & ExtraProps;

const getHeadingProperties = (heading: HeaderTypes) => {
  return Typography.headings[heading as keyof typeof Typography.headings] ?? Typography.Defaults.heading;
};

const getBodyProperties = (variant: BodyTypes) => {
  return Typography.body[variant as keyof typeof Typography.body] ?? Typography.Defaults.body;
};

const getVariantProperties = (variant: TextVariants) => {
  return Typography.variants[variant as keyof typeof Typography.variants] ?? Typography.Defaults.body;
};

const COMMON_STYLES = `
  font-family: ${FontFamily.Default};
  margin: 0;
`;

const TextStyled = styled.p<TextProps>`
  ${COMMON_STYLES}

  ${({ $variant }) => {
    if (!$variant) return null;

    if ($variant in Typography.headings) {
      return getHeadingProperties($variant as HeaderTypes);
    }

    if ($variant in Typography.body) {
      return getBodyProperties($variant as BodyTypes);
    }

    return getVariantProperties($variant as TextVariants);
  }}

  ${({ $typography }) => $typography && TypographyList[$typography]}

  ${({ $fontWeight }) => {
    if ($fontWeight && FontWeight[$fontWeight]) {
      return css`
        font-weight: ${FontWeight[$fontWeight]};
      `;
    }
  }}

  ${({ $color }) =>
    $color &&
    css`
      color: ${Colors.List[$color] ?? Colors.UI.base100};
    `}

  ${({ $truncateHorizontal }) =>
    $truncateHorizontal &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

  ${({ $truncateVertical }) =>
    $truncateVertical &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    `}
  
  ${({ $paddingBottom, $noSpacing, $paddingTop }) => {
    if ($noSpacing) {
      return css`
        padding: 0;
        margin: 0;
      `;
    }

    let bottomValue: number = 0;
    let topValue: number = 0;

    switch ($paddingBottom) {
      case 'paragraph':
        bottomValue = spaces.pBottomPadding;
        break;
      case 'h3':
        bottomValue = spaces.h3Bottom;
        break;
    }

    switch ($paddingTop) {
      case 'paragraph':
        topValue = spaces.pBottomPadding;
        break;
      case 'h3':
        topValue = spaces.h3Bottom;
        break;
      case 'none':
        topValue = 0;
        break;
    }

    return css`
      padding-bottom: ${bottomValue}px;
      ${topValue > 0 && `padding-top: ${topValue}px;`}
    `;
  }}

  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `}

  > a {
    font-weight: ${FontWeight.Bold};
    color: ${Colors.Primary.base100};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Text = forwardRef<HTMLElement, TextProps>(({ children, $hyphen, ...props }, ref) => {
  const isValidElement = props.$variant && (props.$variant in HeaderElements || props.$variant in BodyElements);

  return (
    <TextStyled
      ref={ref as any}
      as={isValidElement ? props.$variant : 'p'}
      {...props}
    >
      {!props.$noBalancer ? <Balancer>{children}</Balancer> : children}
    </TextStyled>
  );
});

Text.displayName = 'Text';

export { Text };
