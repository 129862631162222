import Link from 'next/link';
import styled from 'styled-components';
import { Colors } from 'styles/enums';

const ST0_START_PATH = `M206.5,112.1c-1.3,0-2.4-0.1-3.5,0c-11,0.6-22,0.6-32.9,0.4c-3.6-0.1-7.2-0.4-10.8-0.6c-1.2-0.1-2.5,0-3.7,0 c-0.3,0-0.7,0-1,0c-2.1-0.1-3.6-1.4-3.7-3.3c-0.1-1.8,1.3-3.2,3.4-3.4c4.2-0.4,8.3-0.2,12.5,0.1c2.3,0.2,4.7,0.4,7,0.4 c10,0.1,20-0.1,30.1-0.6c1.8-0.1,3.6-0.3,5.5-0.4c0.5,0,1.1-0.1,1.6-0.1c2.5-0.1,4,1.3,5.1,3.4c0.5,0.9,0.3,1.9,0.1,2.8 c-2.1,10.4-4.8,20.6-7.1,31c-1.6,7.3-2.8,14.7-4.2,22c-0.1,0.5-0.2,1-0.4,1.5c-0.6,1.5-2.1,2.3-3.6,2.1c-1.4-0.2-2.6-1.4-2.7-2.9 c-0.1-0.8,0-1.6,0.1-2.4c1.7-11.1,4-22.1,6.8-33c0.5-1.8,0.9-3.6,1.3-5.4c0.1-0.5,0.2-0.9,0.4-1.7c-0.6,0.5-0.9,0.8-1.2,1.1 c-5.7,6-11.6,11.7-17.9,17.1c-7.1,6.1-14.4,11.7-22.3,16.8c-5.3,3.4-10.8,6.3-16.4,8.9c-4.2,2-8.7,3.1-13.4,3.6 c-5,0.5-9.9,1.3-14.9,1.6c-11.8,0.8-23.5-0.2-34.8-3.8c-4-1.3-7.7-3.3-11.2-5.5c-1.2-0.8-2.4-1.8-3.4-2.8c-2.7-2.7-5.3-5.5-7.1-8.8 c-3-5.5-5.3-11.3-6.1-17.6c-0.6-4.9,0.1-9.7,2.3-14.1c1.7-3.5,3.6-6.8,6.5-9.4c2.5-2.4,5.3-4.4,8.7-5.5c1-0.3,2-0.6,3.1-0.7 c0.7-0.1,1.4,0.1,2.1,0.3c1.4,0.5,2.2,1.7,2.2,3.1c0,1.4-0.9,2.6-2.2,3.1c-0.2,0.1-0.5,0.2-0.7,0.2c-4.5,0.7-7.7,3.3-10.3,6.7 c-2.2,2.8-3.9,5.9-4.6,9.5c-0.6,3.4-0.6,6.7,0.5,10c1,3,2,6.2,3.4,9c2.8,5.5,6.7,10.1,12.2,13.2c5.2,2.9,10.7,4.3,16.5,5.4 c11,2,22,1.6,32.9,0.1c2.1-0.3,4.3-0.5,6.4-0.8c3.2-0.4,6.2-1.4,9.1-2.7c10.4-4.6,19.7-10.8,28.7-17.8c9.3-7.3,17.9-15.3,26.1-23.9 C202.7,116.2,204.5,114.2,206.5,112.1z`;
const ST20_PATH = `M202.3,114.8c-0.5,0-0.9,0-1.4,0c-2.4,0.1-4.9,0.4-7.3,0.4c-3.6-0.1-7.3-0.3-10.9-0.5 c-3.9-0.2-7.7-0.6-11.6-0.9c-3.5-0.3-7-0.5-10.5-0.8c-0.8-0.1-1.7-0.3-2.5-0.7c-1.5-0.8-2.1-2.4-1.7-3.9c0.4-1.5,1.7-2.5,3.4-2.4 c1.5,0,3,0.3,4.4,0.4c5.6,0.4,11.2,0.7,16.8,1.1c2.9,0.2,5.7,0.7,8.6,0.7c3.5,0.1,7-0.2,10.5-0.3c2.8-0.1,5.7-0.1,8.5-0.1 c1.5,0,2.8,0.7,4,1.4c1.4,0.8,1.7,2.2,1.5,3.8c-1.1,6-2.3,12-3.4,18c-1.6,8.5-3.2,17-4.8,25.5c-0.4,2.1-0.6,4.2-1,6.3 c-0.1,0.6-0.3,1.2-0.6,1.8c-0.7,1.4-2.1,2.1-3.6,1.9c-1.5-0.3-2.7-1.4-2.8-3c-0.1-0.4-0.1-0.9,0-1.3c1.8-10.2,3.6-20.3,5.4-30.4 c0.4-2.4,1-4.8,1.5-7.2c0.1-0.4,0.1-0.7,0.2-1.3c-0.3,0.2-0.5,0.2-0.7,0.4c-5.7,5.5-12.1,10-18.8,14.2c-5.8,3.7-11.8,7-17.7,10.5 c-5.7,3.4-11.7,6.2-17.5,9.3c-3.5,1.8-7.2,3.2-10.9,4.3c-5.9,1.7-11.9,3.5-17.9,5c-2.8,0.7-5.7,1.1-8.5,1.3 c-4.6,0.2-9.2,0.1-13.8,0.1c-3,0-6-0.6-8.9-1.6c-6.5-2.2-12.7-5.1-18-9.7c-4.3-3.8-7.6-8.3-10.2-13.5c-1.7-3.5-2.3-7.3-2.5-11.2 c-0.2-4.8,0-9.5,0.8-14.2c0.4-2.2,1.1-4.3,1.9-6.3c0.3-0.9,1-1.7,1.8-2.4c1.2-1,3.1-0.9,4.3,0.1c1.2,1,1.6,2.6,0.9,4 c-1.4,2.5-2,5.3-2.2,8.1c-0.4,4.4-0.7,8.8-0.1,13.2c0.3,2.7,1.1,5.3,2.5,7.6c3,5,6.7,9.4,11.7,12.5c7.4,4.5,15.3,7.1,24,6.5 c1.5-0.1,3.1-0.1,4.6-0.1c4.2-0.1,8.4-0.6,12.5-1.8c5.9-1.8,11.9-3.4,17.8-5.2c2.1-0.6,4-1.6,6-2.5c2-0.9,4-2,6.1-3 c6-2.7,11.4-6.3,17.1-9.4c5.8-3.1,11.3-6.8,16.8-10.3c5.4-3.5,10.4-7.4,14.8-12c0.5-0.5,1-1.2,1.4-1.7 C202.5,115.1,202.4,114.9,202.3,114.8z`;
const ST40_PATH = `M210.2,111.2c-2.1-0.2-4.3-0.4-6.4-0.6c-3.1-0.3-6.2-0.8-9.3-1.1c-6.7-0.6-13.4-1.4-20.2-1.6 c-4.8-0.1-9.6-0.5-14.4-0.8c-1.2-0.1-2.5,0-3.7-0.1c-0.6,0-1.2-0.1-1.7-0.2c-1.5-0.4-2.4-1.7-2.4-3.2c0-1.5,0.9-2.8,2.4-3.2 c0.7-0.2,1.5-0.3,2.3-0.3c4.6,0.2,9.1,0.4,13.6,0.6c1.3,0.1,2.6,0.2,3.9,0.2c5.5,0.2,11,0.6,16.5,1.2c5.8,0.7,11.6,1.2,17.4,1.8 c2.6,0.3,5.2,0,7.7-0.3c0.4-0.1,0.9-0.1,1.3-0.1c3.9-0.2,6.2,2.5,5.6,6.3c-0.7,4-2.1,7.9-3.4,11.7c-0.9,2.8-2,5.5-3,8.3 c-1.4,4-2.8,8-4.1,12c-2.1,6.9-4.4,13.7-7,20.4c-1,2.7-1.8,5.6-2.8,8.3c-0.1,0.5-0.3,0.9-0.5,1.3c-0.8,1.5-2.4,2.3-4,1.9 c-1.5-0.4-2.6-1.9-2.5-3.6c0-0.6,0.2-1.2,0.4-1.8c1.3-3.9,2.6-7.8,4-11.7c1.5-4.3,3-8.5,4.4-12.8c2.2-7,4.5-14,7.2-20.8 c0.5-1.3,0.9-2.6,1.2-4.1c-0.3,0.1-0.5,0.3-0.8,0.5c-4.4,3.7-8.8,7.4-13.3,11c-3.2,2.5-6.6,4.8-9.9,7.2 c-5.3,3.7-10.7,7.3-16.3,10.6c-3.8,2.2-7.6,4.5-11.5,6.5c-2.2,1.2-4.6,2.1-7,3.1c-5.5,2.2-11,4.3-16.5,6.4 c-3.2,1.3-6.7,1.8-10.1,2.4c-3.4,0.6-6.8,1.1-10.2,1.6c-0.3,0-0.6,0.1-0.9,0.1c-6.7,0-13.3,0.3-20-0.4c-4.1-0.4-8.1-1.1-11.9-2.6 c-6.9-2.8-13.5-6.3-19.2-11.1c-1.4-1.2-2.7-2.4-3.9-3.9c-2.6-3.3-5.1-6.6-7.6-10c-1.6-2.2-2.4-4.7-2.7-7.3c-0.1-0.5-0.1-1.1,0-1.6 c0.1-1.6,1.2-2.8,2.8-3.1c1.5-0.3,3,0.5,3.6,2c0.3,0.7,0.4,1.6,0.6,2.3c0.4,1.1,0.6,2.4,1.2,3.3c2.3,3.7,5,7.3,8,10.5 c3.4,3.7,7.7,6.4,12.2,8.8c1.4,0.8,2.9,1.4,4.4,2.1c4.3,2.1,8.8,3.1,13.6,3.5c6,0.5,12,0.5,18,0.2c4-0.2,8-1.2,11.9-1.7 c6.6-0.9,12.6-3.7,18.7-6c3.5-1.4,7-2.9,10.5-4.3c0.4-0.2,0.8-0.3,1.2-0.5c6.6-3.9,13.3-7.5,19.7-11.7c6.2-4,12.3-8.4,18.1-13 c4.8-3.7,9.6-7.5,14-11.7c0.3-0.3,0.6-0.4,0.9-0.6C210.4,111.5,210.3,111.4,210.2,111.2z`;
const ST60_PATH = `M210,106.6c-0.8,0-1.3,0-1.8,0c-9.4,0.2-18.8,0.4-28.2,0.5c-5.6,0-11.2-0.2-16.8-0.3c-3.1-0.1-6.1-0.1-9.2-0.2 c-0.5,0-1.1,0-1.6-0.1c-1.5-0.4-2.5-1.5-2.6-2.9c-0.1-1.5,0.6-2.9,2.1-3.4c0.8-0.3,1.7-0.5,2.5-0.5c2.8,0,5.7,0,8.5,0.1 c9.7,0.5,19.4,0.3,29,0c3.4-0.1,6.9-0.1,10.4-0.1c4.5-0.1,9-0.2,13.5-0.2c1.5,0,3.1,0.2,4.6,0.4c1.7,0.3,2.9,2,2.6,3.7 c-0.1,0.5-0.2,1-0.4,1.5c-1.2,2.7-1.8,5.6-2.6,8.5c-1.5,6-3.2,12-4.9,17.9c-1.5,5.2-3.4,10.4-5,15.6c-1.7,5.6-3.3,11.2-4.9,16.8 c-0.7,2.5-1.5,5-2.3,7.4c-0.2,0.6-0.5,1.3-0.9,1.8c-0.9,1.2-2.3,1.7-3.7,1.3c-1.4-0.4-2.4-1.7-2.4-3.2c0-0.7,0.1-1.4,0.3-2.1 c1.8-5.7,3.6-11.4,5.4-17.2c1.8-5.9,3.6-11.7,5.3-17.6c1.9-6.3,3.7-12.6,5.6-18.9c0.1-0.4,0.2-0.8,0.3-1.3 c-0.2,0.1-0.4,0.1-0.5,0.1c-5.2,5.1-10.8,9.6-16.4,14.2c-4.9,4.1-9.7,8.3-14.9,12c-6.9,5-14.2,9.6-22,13.4 c-3.8,1.8-7.6,3.7-11.4,5.6c-4.7,2.3-9.6,4.3-14.5,5.8c-8.7,2.8-17.6,3.7-26.7,2.9c-3.9-0.4-7.7-0.9-11.5-1.6 c-0.4-0.1-0.8-0.1-1.1-0.2c-7.2-2.1-14.5-3.6-21.4-6.6c-4.7-2.1-9.5-3.9-14.2-6c-1.9-0.9-3.7-2.1-5.4-3.2c-0.6-0.4-1.1-1.1-1.5-1.7 c-0.7-1.3-0.4-2.7,0.6-3.7c1-1,2.2-1.3,3.6-0.9c0.5,0.2,1.1,0.5,1.5,0.8c1.9,1.4,4,2.4,6.2,3.3c4.2,1.7,8.4,3.5,12.6,5.3 c4.7,2,9.5,3.3,14.4,4.5c0.5,0.1,0.9,0.2,1.4,0.3c5.2,1.8,10.6,2.4,16.1,2.9c10.3,0.9,20-0.9,29.5-4.8c7.5-3.1,14.8-6.6,22-10.4 c8.2-4.4,16.2-9.4,23.4-15.4c7.3-6.2,14.7-12.3,22-18.5c1.7-1.4,3.2-3,4.8-4.6C209.5,107.3,209.6,107.1,210,106.6z`;
const ST80_PATH = `M213.9,107.9c-0.5,0-1,0-1.5,0c-5.9,0-11.8,0.1-17.7-0.1c-7-0.2-14.1-0.7-21.1-1.2c-5.5-0.4-11.1-1.1-16.6-1.7 c-0.8-0.1-1.6-0.1-2.4-0.2c-0.4,0-0.9,0-1.3-0.1c-1.5-0.3-2.6-1.5-2.6-3c0-1.4,0.8-2.8,2.3-3.1c1-0.3,2.2-0.3,3.3-0.2 c2.5,0.2,5,0.6,7.4,0.8c4.9,0.5,9.7,0.9,14.6,1.4c13.2,1.3,26.4,0.9,39.5,0.9c1.1,0,2.3-0.3,3.4-0.4c0.7,0,1.5-0.1,2.1,0.1 c1.4,0.4,2.2,1.8,2.1,3.2c-0.2,3.5-1.2,6.8-2.4,10c-1.4,3.8-2.8,7.6-4.1,11.4c-1.8,5.2-3.5,10.5-5.2,15.7 c-2.3,7.1-4.6,14.2-7.1,21.3c-1.3,3.6-2.8,7.1-4.3,10.7c-0.2,0.5-0.5,0.9-0.8,1.3c-1,1.1-2.4,1.4-3.7,0.8c-1.2-0.6-2-1.9-1.8-3.3 c0.1-0.5,0.2-1,0.4-1.5c0.5-1.3,1-2.7,1.6-4c1.4-3.1,2.5-6.2,3.5-9.4c1.9-6,4-12,6-18c2.3-6.8,4.5-13.6,6.8-20.5 c0.1-0.3,0.1-0.5,0.3-1c-0.4,0.2-0.7,0.3-0.8,0.5c-5.1,4.9-10.5,9.3-16.3,13.2c-5.4,3.7-10.7,7.5-16.1,11.1 c-7.6,5.2-15.8,9.2-24.4,12.4c-8.7,3.3-17.5,6.4-26.6,8.3c-6.7,1.4-13.5,2.6-20.3,3.9c-13.3,2.5-26.7,3.2-40.1,2.5 c-5.2-0.2-10.3-1.1-15.4-1.7c-1.2-0.1-2.3-0.6-3.5-0.9c-0.9-0.3-1.7-0.9-2.1-1.8c-1.1-2.2,0.4-4.7,2.9-4.6c0.2,0,0.5,0,0.7,0.1 c4.7,1.6,9.6,1.8,14.5,2.2c10.9,1,21.8,0.6,32.6-0.8c11.8-1.6,23.6-3.5,35.2-6.7c11.8-3.3,23.4-7.2,34.2-13.1c2-1.1,4-2.2,6-3.4 c8.1-5.2,16.1-10.7,23.8-16.5c4.3-3.2,8.3-6.6,12-10.5c1.2-1.2,2.2-2.5,3.3-3.7C214.1,108.2,214,108.1,213.9,107.9z`;
const ST100_PATH = `M213.9,107.9c-0.5,0-1,0-1.5,0c-5.9,0-11.8,0.1-17.7-0.1c-7-0.2-14.1-0.7-21.1-1.2c-5.5-0.4-11.1-1.1-16.6-1.7 c-0.8-0.1-1.6-0.1-2.4-0.2c-0.4,0-0.9,0-1.3-0.1c-1.5-0.3-2.6-1.5-2.6-3c0-1.4,0.8-2.8,2.3-3.1c1-0.3,2.2-0.3,3.3-0.2 c2.5,0.2,5,0.6,7.4,0.8c4.9,0.5,9.7,0.9,14.6,1.4c13.2,1.3,26.4,0.9,39.5,0.9c1.1,0,2.3-0.3,3.4-0.4c0.7,0,1.5-0.1,2.1,0.1 c1.4,0.4,2.2,1.8,2.1,3.2c-0.2,3.5-1.2,6.8-2.4,10c-1.4,3.8-2.8,7.6-4.1,11.4c-1.8,5.2-3.5,10.5-5.2,15.7 c-2.3,7.1-4.6,14.2-7.1,21.3c-1.3,3.6-2.8,7.1-4.3,10.7c-0.2,0.5-0.5,0.9-0.8,1.3c-1,1.1-2.4,1.4-3.7,0.8c-1.2-0.6-2-1.9-1.8-3.3 c0.1-0.5,0.2-1,0.4-1.5c0.5-1.3,1-2.7,1.6-4c1.4-3.1,2.5-6.2,3.5-9.4c1.9-6,4-12,6-18c2.3-6.8,4.5-13.6,6.8-20.5 c0.1-0.3,0.1-0.5,0.3-1c-0.4,0.2-0.7,0.3-0.8,0.5c-5.1,4.9-10.5,9.3-16.3,13.2c-5.4,3.7-10.7,7.5-16.1,11.1 c-7.6,5.2-15.8,9.2-24.4,12.4c-8.7,3.3-17.5,6.4-26.6,8.3c-6.7,1.4-13.5,2.6-20.3,3.9c-13.3,2.5-26.7,3.2-40.1,2.5 c-5.2-0.2-10.3-1.1-15.4-1.7c-1.2-0.1-2.3-0.6-3.5-0.9c-0.9-0.3-1.7-0.9-2.1-1.8c-1.1-2.2,0.4-4.7,2.9-4.6c0.2,0,0.5,0,0.7,0.1 c4.7,1.6,9.6,1.8,14.5,2.2c10.9,1,21.8,0.6,32.6-0.8c11.8-1.6,23.6-3.5,35.2-6.7c11.8-3.3,23.4-7.2,34.2-13.1c2-1.1,4-2.2,6-3.4 c8.1-5.2,16.1-10.7,23.8-16.5c4.3-3.2,8.3-6.6,12-10.5c1.2-1.2,2.2-2.5,3.3-3.7C214.1,108.2,214,108.1,213.9,107.9z`;

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  svg path.st0 {
    fill: ${Colors.Yellow.base100};
  }

  &:focus {
    background-color: ${Colors.Turkish.base100};
  }

  &:focus svg path.st0 {
    fill: ${Colors.Turkish.base100};
  }

  &:hover svg path.st1 {
    animation: transform_path 90ms forwards;
  }

  @keyframes transform_path {
    0% {
      d: path("${ST0_START_PATH}");
    }

    20% {
      d: path("${ST20_PATH}");
    }

    40% {
      d: path("${ST40_PATH}");
    }

    60% {
      d: path("${ST60_PATH}");
    }

    80% {
      d: path("${ST80_PATH}");
    }

    100% {
      d: path("${ST100_PATH}");
    }
  }
`;
