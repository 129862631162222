export const button = {
  /* PRIMARY */
  primary: {
    border: {
      default: {
        width: '1',
        radius: '30',
        unit: 'px'
      },
      hover: { width: '0', radius: '4', unit: 'px' },
      focus: { width: '0', radius: '4', unit: 'px' },
      visible: { width: '0', radius: '4', unit: 'px' },
      disabled: { width: '0', radius: '4', unit: 'px' }
    },
  },
  /* OUTLINE */
  outline: {
    border: {
      default: {
        width: '1',
        radius: '4',
        unit: 'px'
      },
      hover: { width: '1', radius: '4', unit: 'px' },
      focus: { width: '1', radius: '4', unit: 'px' },
      visible: { width: '1', radius: '4', unit: 'px' },
      disabled: { width: '1', radius: '4', unit: 'px' }
    },
  },
};
