export const button = {
  normal: {
    dimensions: {
      unit: 'px',
      values: {
        height: null,
        width: null
      }
    },
    // All values are minimum padding
    padding: {
      unit: 'px',
      values: {
        top: '6',
        right: '16',
        bottom: '6',
        left: '16'
      }
    },
    margin: {
      unit: 'px',
      values: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0'
      }
    },
    gap: {
      unit: 'px',
      value: '10'
    }
  },
};
