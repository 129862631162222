'use client';

import { forwardRef } from 'react';

import { StyledLink } from './link.style';
import { Text } from '@aurora-components';
import { Linkicon, RightArrow } from 'icons/components';

interface LinkProps {
  title: string;
  url: string;
  target?: string;
  variant?: 'default' | 'with-arrow';
}
const Link = forwardRef<HTMLAnchorElement, LinkProps>(({ title, url, variant = 'default', target = '_self' }, ref) => {
  return (
    <StyledLink
      href={{
        pathname: url,
      }}
      ref={ref}
      target={target}
    >
      <Text
        $variant="link"
        $color="primary-base100"
        $fontWeight="SemiBold"
        $noBalancer={true}
      >
        {title}
      </Text>
      {variant === 'default' ? (
        <Linkicon
          height={40}
          width={40}
        />
      ) : (
        <RightArrow />
      )}
    </StyledLink>
  );
});

Link.displayName = 'Link';

export { Link };
