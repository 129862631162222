import React, { Ref, SVGProps, forwardRef } from 'react';

// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit

const PlaneIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    viewBox="0 0 87 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 87}
    height={props.height || 66}
    {...props}
  >
    <path
      d="M22.3152 29.9128C21.378 36.9498 19.6582 51.1141 20.2767 51.4748L33.6809 39.6861M22.3152 29.9128L7.90588 16.5368L85.46 19.5933M22.3152 29.9128L80.1161 19.3827M85.46 19.5933C79.3368 26.9079 74.7824 32.3257 68.6631 39.6015C63.9896 45.1583 54.9938 56.7943 52.8616 59.3929L33.6809 39.6861M85.46 19.5933L33.6809 39.6861"
      stroke="#001B71"
      strokeWidth="1.65906"
      strokeLinecap="round"
      strokeLinejoin="round"
      />
  </svg>
));

export default PlaneIcon;
