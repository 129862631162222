interface IPadding {
  unit: string;
  values: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  };
}
interface IDimensions {
  unit: string;
  values: {
    height: string | null;
    width: string | null;
  };
}

interface IMargin {
  unit: string;
  values: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  };
}

interface IButtonSizes {
  dimensions: IDimensions;
  padding: IPadding;
  margin: IMargin;
}

/**
 * Accepts a padding object and returns a css string
 * @param {IPadding} padding
 * @return {string =>}
 */
export const paddingGenerator = (padding: IPadding): string => {
  const { unit, values } = padding;

  const top = values.top + unit;
  const right = values.right + unit;
  const bottom = values.bottom + unit;
  const left = values.left + unit;

  return `${top} ${right} ${bottom} ${left};`;
};

/**
 * Accepts a margin object and returns a css string
 * @param {IMargin} padding
 * @return {string =>}
 */
export const marginGenerator = (padding: IMargin): string => {
  const { unit, values } = padding;

  const top = values.top + unit;
  const right = values.right + unit;
  const bottom = values.bottom + unit;
  const left = values.left + unit;

  return `${top} ${right} ${bottom} ${left};`;
};

interface IBoxShadow {
  unit: string;
  values: {
    offsetX: string;
    offsetY: string;
    blur: string;
    spread: string;
  };
}

/**
 * Accepts a shadow object containing values for box-shadow, and unit and reutrns a CSS string
 * @param {IBoxShadow} shadow
 * @param {string} color
 * @return {string =>}
 */
export const shadowGenerator = (shadow: IBoxShadow, color: string): string => {
  const { unit, values } = shadow;

  const offsetX = values.offsetX + unit;
  const offsetY = values.offsetY + unit;
  const blur = values.blur + unit;
  const spread = values.spread + unit;

  return `${offsetX} ${offsetY} ${blur} ${spread} ${color};`;
};

/**
 * Accepts the SizeTokens.button.[size] object and returns the CSS string
 * @param {IButtonSizes} sizeValues
 * @return {string =>}
 */
export const sizeValuesGenerator = (sizeValues: IButtonSizes): string => {
  const { dimensions, padding, margin } = sizeValues;

  const { unit, values } = dimensions;
  const { height, width } = values;

  const heightValue = height ? height + unit : 'auto';
  const widthValue = width ? width + unit : 'auto';

  const paddingValue = paddingGenerator(padding);
  const marginValue = marginGenerator(margin);

  return `height:${heightValue}; width:${widthValue}; padding:${paddingValue}; margin:${marginValue}`;
};
