import * as React from 'react';
import { SVGProps } from 'react';

// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
const MobileMenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 16}
    {...props}
  >
    <line
      y1="1.25"
      x2="24"
      y2="1.25"
      stroke="#001B71"
      strokeWidth="1.5"
    />
    <line
      y1="7.25"
      x2="24"
      y2="7.25"
      stroke="#001B71"
      strokeWidth="1.5"
    />
    <line
      y1="13.25"
      x2="24"
      y2="13.25"
      stroke="#001B71"
      strokeWidth="1.5"
    />
  </svg>
);
export default MobileMenuIcon;
