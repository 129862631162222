export { default as Caret } from './Caret';
export { default as Linkicon } from './Linkicon';
export { default as LogoIcon } from './LogoIcon';
export { default as MobileMenuIcon } from './MobileMenuIcon';
export { default as CloseMenuIcon } from './CloseMenuIcon';
export { default as PlayVideoIcon } from './PlayVideoIcon';
export { default as IcelandAirLogo } from './IcelandAirLogo';
export { default as PlaneIcon } from './PlaneIcon';
export { default as IcelandicFlag } from './IcelandicFlag';
export { default as EnglishFlag } from './EnglishFlag';
export { default as Globe } from './Globe';
export { default as RightArrow } from './RightArrow';
export { default as PeopleDoodleIcon } from './PeopleDoodleIcon';
export { default as AltPeopleDoodle } from './AltPeopleDoodle';
