import { ReactElement, ReactNode, forwardRef } from 'react';

import Link from 'next/link';

import styled, { css } from 'styled-components';

import { Button as ButtonStyle } from 'styles/component-styles/button';
import { ButtonTypes, ButtonVariants } from 'styles/constants';

type ButtonElements = 'span' | 'button' | 'div' | 'a';
interface ButtonProps {
  as?: ButtonElements;
  type?: ButtonTypes;
  /**
   * Button variant
   */
  $variant?: ButtonVariants;
  href?: string;

  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Text content
   */
  children: ReactNode;
  fluid?: boolean;
  disabled?: boolean;
  target?: string;
  className?: string;
  tabIndex?: number;
}

const sharedButtonStyles = css<ButtonProps>`
  ${(props) => {
    switch (props.$variant) {
      case 'primary':
        return ButtonStyle.primary;
      case 'outline':
        return ButtonStyle.outline;
      default:
        return ButtonStyle.primary;
    }
  }};
`;
const StyledButton = styled.button<ButtonProps>`
  ${sharedButtonStyles}
`;

const StyledLink = styled(Link)<ButtonProps>`
  ${sharedButtonStyles}
`;

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    { as = 'button', $variant = 'primary', target = '_self', onClick, children, href, tabIndex, ...props },
    ref
  ): ReactElement => {
    if (as === 'a' && href) {
      return (
        <StyledLink
          href={href}
          $variant={$variant}
          target={target}
          tabIndex={tabIndex}
          onClick={onClick}
          {...props}
        >
          {children}
        </StyledLink>
      );
    }

    return (
      <StyledButton
        onClick={onClick}
        ref={ref as React.Ref<HTMLButtonElement>}
        $variant={$variant}
        type={props.type}
        {...props}
      >
        {children}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';

export { Button };
