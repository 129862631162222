export enum Primary {
  base100 = '#001B71',
  base090 = '#1A327F',
  base080 = '#33498D',
  base070 = '#4D5F9C',
  base060 = '#6676aa',
  base050 = '#808DB8',
  base040 = '#99A4C6',
  base030 = '#B3BBD4',
  base020 = '#CCD1E3',
  base010 = '#e6e8f1',
  base005 = '#F2F4F8',
}

export enum Economy {
  base100 = '#0C68FF',
  base090 = '#2477ff',
  base080 = '#3d86ff',
  base070 = '5595ff',
  base060 = '#6DA4FF',
  base040 = '#85B3FF',
  base030 = '#B6D2FF',
  base020 = '#CEE1FF',
  base010 = '#E7F0FF',
  base005 = '#F3F7FF',
}

export enum Pink {
  base100 = '#FF47B3',
  base090 = '#FF59BB',
  base080 = '#FF6CC2',
  base070 = '#FF7ECA',
  base060 = '#FF91D1',
  base050 = '#FFA3D9',
  base040 = '#FFB5E1',
  base030 = '#FFC8E8',
  base020 = '#FFDAF0',
  base010 = '#FFEDF7',
  base005 = '#FFF6FB',
}
export enum Green {
  base100 = '#50E68C',
  base090 = '#61E897',
  base080 = '#73EBA3',
  base070 = '#85EEAF',
  base060 = '#96F0BA',
  base050 = '#A7F2C5',
  base040 = '#B9F5D1',
  base030 = '#CBF8DD',
  base020 = '#DCFAE8',
  base010 = '#EEFCF4',
  base005 = '#F6FEF9',
}

export enum Turkish {
  base100 = '#91F5E1',
  base090 = '#9CF6E4',
  base080 = '#A7F7E7',
  base070 = '#B2F8EA',
  base060 = '#BDF9ED',
  base050 = '#C8FAF0',
  base040 = '#D3FBF3',
  base030 = '#DEFCF6',
  base020 = '#E9FDF9',
  base010 = '#F4FEFC',
  base005 = '#FAFFFD',
}

export enum LightBlue {
  base100 = '#55C0E8',
  base090 = '#66C6EA',
  base080 = '#77CDED',
  base070 = '#88D3EF',
  base060 = '#99D9F1',
  base050 = '#AADFF3',
  base040 = '#BBE6F6',
  base030 = '#CCECF8',
  base020 = '#DDF2FA',
  base010 = '#EEF9FD',
  base005 = '#F7FCFE',
}

export enum Yellow {
  base100 = '#FFDA00',
  base090 = '#FEDE1A',
  base080 = '#FEE133',
  base070 = '#FEE54D',
  base060 = '#FEE966',
  base050 = '#FFE966',
  base040 = '#FFF099',
  base030 = '#FFF4B3',
  base020 = '#FFF8CC',
  base010 = '#FFFBE6',
  base005 = '#FFFDF2',
}

export enum Purple {
  base100 = '#BE00FF',
  base090 = '#C41AFF',
  base080 = '#CB33FF',
  base070 = '#D24DFF',
  base060 = '#D866FF',
  base050 = '#DE80FF',
  base040 = '#E599FF',
  base030 = '#ECB3FF',
  base020 = '#F2CCFF',
  base010 = '#F9E6FF',
  base005 = '#FCF2FF',
}

export enum Red {
  base100 = '#ED1515',
  base090 = '#EF2C2C',
  base080 = '#F14444',
  base070 = '#F25B2B',
  base060 = '#F47373',
  base050 = '#F68A8A',
  base040 = '#F8A1A1',
  base030 = '#FAB9B9',
  base020 = '#FBD0D0',
  base010 = '#FDE8E8',
  base005 = '#FEF3F3',
}

export enum UI {
  base100 = '#435064',
  base090 = '#566173',
  base080 = '#697383',
  base070 = '#7B8593',
  base050 = '#A1A7B1',
  base040 = '#B4B9C1',
  base030 = '#C7CBD1',
  base020 = '#D9DCE0',
  base010 = '#ECEEF0',
  base005 = '#F6F6F7',
  white = '#FFFFFF',
  beigeWhite = '#FBF7F2',
  error = '#ED1515',
  transparent = 'transparent',
  textGray = '#333333',
}

export enum Gray {
  base = '#333333',
  Gray19 = '#303030',
}

export const Secondary = {
  green: Green,
  turkish: Turkish,
  purple: Purple,
  lightBlue: LightBlue,
  yellow: Yellow,
};

export const withOpacity = {
  primary: {
    '10%': '#001B711A'
  }
}

type ColorsList =
  | `primary-${keyof typeof Primary}`
  | `economy-${keyof typeof Economy}`
  | `pink-${keyof typeof Pink}`
  | `green-${keyof typeof Green}`
  | `turkish-${keyof typeof Turkish}`
  | `lightBlue-${keyof typeof LightBlue}`
  | `yellow-${keyof typeof Yellow}`
  | `purple-${keyof typeof Purple}`
  | `red-${keyof typeof Red}`
  | `ui-${keyof typeof UI}`;

type ColorsKey =
  | keyof typeof Primary
  | keyof typeof Economy
  | keyof typeof Pink
  | keyof typeof Green
  | keyof typeof Turkish
  | keyof typeof LightBlue
  | keyof typeof Yellow
  | keyof typeof Purple
  | keyof typeof Red
  | keyof typeof UI;

type FlattenedType = Record<ColorsList, string>;

const flatten = <T extends Record<string, string>>(prefix: string, obj: T): FlattenedType => {
  let retObj = {} as FlattenedType;

  Object.keys(obj).forEach((key) => {
    const k = key as ColorsKey;
    const newKey = `${prefix}-${k}` as ColorsList;

    retObj[newKey] = obj[k];
  });

  return retObj;
};

export const List = {
  ...flatten('primary', Primary),
  ...flatten('economy', Economy),
  ...flatten('pink', Pink),
  ...flatten('green', Green),
  ...flatten('turkish', Turkish),
  ...flatten('lightBlue', LightBlue),
  ...flatten('yellow', Yellow),
  ...flatten('purple', Purple),
  ...flatten('red', Red),
  ...flatten('ui', UI),
};
