import * as React from 'react';
import { SVGProps } from 'react';

// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
const SvgLinkicon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 274.1 274.4"
    xmlSpace="preserve"
    width={props.width || "270"}
    height={props.height || "270"}
  >
    <g id="Layer_1"></g>
    <g id="Utlinur">
	    <g>
		    <path className="st0" fill="#FFDA00" d="M133.9,274.3c-6.4,0.5-12.8-0.8-19.2-1.8c-6.5-1.1-12.8-2.7-19.1-4.9c-4.4-1.5-8.9-2.8-13.3-4.4
			  c-7.4-2.6-14.8-5.2-21.9-8.4c-2.1-1-4.2-2.1-6.1-3.4c-2.3-1.5-4.7-3-7.1-4.4c-2.9-1.7-5.6-3.8-8.1-6.2c-1.7-1.7-3.6-3.2-5.2-5
        c-3.8-4.1-7.4-8.4-10.7-13c-3.1-4.3-5.6-9.1-8.2-13.7c-1.4-2.5-2.8-4.9-4-7.5c-2.4-4.7-4.2-9.6-5.7-14.6c-1.5-5-2.9-10-3.7-15.2
        c-0.5-3.4-0.8-6.8-1.1-10.1c-0.3-4.4-0.6-8.7-0.5-13.1c0.1-3.5,0.2-7.1,0.5-10.6c0.2-2.9,0.3-5.7,0.7-8.6c0.8-7.5,2.1-15,4-22.4
        c1.1-4.2,2.5-8.4,3.7-12.5c1.5-5.3,4.1-10.1,6.7-14.9c2.4-4.5,5.1-8.9,8-13.2c2-3,4.1-5.9,6.2-8.8c1.7-2.4,3.5-4.8,5.4-7.1
        c3-3.7,6.3-7,9.7-10.2c5.6-5.4,11.6-10.4,18.1-14.7c6.3-4.2,12.7-8.2,19.5-11.7c4.5-2.3,9.1-4.6,13.8-6.4c4.4-1.7,9-2.9,13.7-3.8
        c3-0.6,6-1.2,9.1-1.6c5.4-0.7,10.8-1.4,16.3-2.1c5.7-0.7,11.4,0,17,0.9c5.2,0.8,10.2,2.2,15.4,3.2c3,0.6,6,1.1,9,1.7
        c4,0.8,7.8,2.2,11.6,3.9c3.1,1.4,6.3,2.7,9.4,4.1c4.1,1.8,8.2,3.7,11.5,6.7c4.3,3.9,9.4,6.6,14.2,9.8c5.1,3.4,10.1,6.8,14.7,10.8
        c1.7,1.4,3.4,2.8,5,4.3c2,1.7,3.7,3.7,5.3,5.8c2.2,3,4.5,5.8,6.3,9.1c1.6,2.8,3,5.6,4.2,8.5c1.6,3.8,3.7,7.4,5.1,11.3
        c2.6,7.1,4.8,14.4,5.9,21.9c0.7,4.5,1.7,9,2.3,13.5c0.5,3.9,1.1,7.8,1.4,11.7c0.5,5.8,0.6,11.6,0.5,17.4c-0.1,3.2-0.4,6.3-0.7,9.5
        c-0.1,1.4-0.2,2.9-0.3,4.3c-0.3,4.5-1.3,8.9-2.2,13.3c-1.7,8.3-4.3,16.3-6.9,24.4c-0.7,2.2-1.6,4.3-2.4,6.5
        c-0.4,1-0.9,1.8-1.7,2.5c-0.3,0.3-0.7,0.5-0.7,1c-0.2,2.3-1.3,4.3-2.4,6.3c-3.2,5.2-6.4,10.4-10.2,15.1c-2.6,3.2-5.6,6.2-8.7,9
        c-2.4,2.1-4.6,4.3-7.1,6.3c-3.6,2.9-7.5,5.5-11.5,7.9c-6.6,4-13.5,7.5-20.6,10.5c-3.1,1.3-6.1,2.6-9.1,3.9c-2.5,1.1-5,1.9-7.7,2.5
        c-2.9,0.7-5.8,1.5-8.6,2.4c-2.7,0.9-5.5,1.2-8.3,1.7c-4,0.6-8,1.3-11.9,1.9c-2.7,0.4-5.5,0.7-8.3,0.8
        C141.1,274.1,137.5,274.5,133.9,274.3z"/>
	    </g>
	    <path className="st1" d="M206.5,112.1c-1.3,0-2.4-0.1-3.5,0c-11,0.6-22,0.6-32.9,0.4c-3.6-0.1-7.2-0.4-10.8-0.6c-1.2-0.1-2.5,0-3.7,0
        c-0.3,0-0.7,0-1,0c-2.1-0.1-3.6-1.4-3.7-3.3c-0.1-1.8,1.3-3.2,3.4-3.4c4.2-0.4,8.3-0.2,12.5,0.1c2.3,0.2,4.7,0.4,7,0.4
        c10,0.1,20-0.1,30.1-0.6c1.8-0.1,3.6-0.3,5.5-0.4c0.5,0,1.1-0.1,1.6-0.1c2.5-0.1,4,1.3,5.1,3.4c0.5,0.9,0.3,1.9,0.1,2.8
        c-2.1,10.4-4.8,20.6-7.1,31c-1.6,7.3-2.8,14.7-4.2,22c-0.1,0.5-0.2,1-0.4,1.5c-0.6,1.5-2.1,2.3-3.6,2.1c-1.4-0.2-2.6-1.4-2.7-2.9
        c-0.1-0.8,0-1.6,0.1-2.4c1.7-11.1,4-22.1,6.8-33c0.5-1.8,0.9-3.6,1.3-5.4c0.1-0.5,0.2-0.9,0.4-1.7c-0.6,0.5-0.9,0.8-1.2,1.1
        c-5.7,6-11.6,11.7-17.9,17.1c-7.1,6.1-14.4,11.7-22.3,16.8c-5.3,3.4-10.8,6.3-16.4,8.9c-4.2,2-8.7,3.1-13.4,3.6
        c-5,0.5-9.9,1.3-14.9,1.6c-11.8,0.8-23.5-0.2-34.8-3.8c-4-1.3-7.7-3.3-11.2-5.5c-1.2-0.8-2.4-1.8-3.4-2.8c-2.7-2.7-5.3-5.5-7.1-8.8
        c-3-5.5-5.3-11.3-6.1-17.6c-0.6-4.9,0.1-9.7,2.3-14.1c1.7-3.5,3.6-6.8,6.5-9.4c2.5-2.4,5.3-4.4,8.7-5.5c1-0.3,2-0.6,3.1-0.7
        c0.7-0.1,1.4,0.1,2.1,0.3c1.4,0.5,2.2,1.7,2.2,3.1c0,1.4-0.9,2.6-2.2,3.1c-0.2,0.1-0.5,0.2-0.7,0.2c-4.5,0.7-7.7,3.3-10.3,6.7
        c-2.2,2.8-3.9,5.9-4.6,9.5c-0.6,3.4-0.6,6.7,0.5,10c1,3,2,6.2,3.4,9c2.8,5.5,6.7,10.1,12.2,13.2c5.2,2.9,10.7,4.3,16.5,5.4
        c11,2,22,1.6,32.9,0.1c2.1-0.3,4.3-0.5,6.4-0.8c3.2-0.4,6.2-1.4,9.1-2.7c10.4-4.6,19.7-10.8,28.7-17.8c9.3-7.3,17.9-15.3,26.1-23.9
        C202.7,116.2,204.5,114.2,206.5,112.1z"/>
    </g>
  </svg>
);

export default SvgLinkicon;
