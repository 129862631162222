'use client';

import React, { ReactElement, ReactNode, forwardRef } from 'react';

import styled, { RuleSet, css } from 'styled-components';
import { hexToRgb } from 'utils/hexToRgb';

import { BreakpointKeys, Sizes, SpaceProp, breakpoints, spaces } from 'styles/constants';
import { Colors } from 'styles/enums';
import { HEADER_HEIGHT } from 'styles/tokens/spacing';

export type ContainerElements = 'div' | 'span' | 'section';

interface BaseProps {
  children?: ReactNode;
  $as?: ContainerElements;
  $ariaLabel?: string;
  $dataCy?: string;
  $maxWidth?: boolean | 'screen';
  $centered?: boolean;
  $bg?: keyof typeof Colors.List;
  $bgOpacity?: number;
  $fullHeight?: boolean;
  $overflowHidden?: boolean;
  $flexY?: boolean;
  $flexX?: boolean;
  $flexGrow?: boolean;
  $pageContainer?: boolean;
}

export interface ContainerProps {
  $margin?: SpaceProp;
  $marginTop?: SpaceProp;
  $marginRight?: SpaceProp;
  $marginBottom?: SpaceProp;
  $marginLeft?: SpaceProp;
  $marginY?: SpaceProp;
  $marginX?: SpaceProp;
  $padding?: SpaceProp;
  $paddingTop?: SpaceProp;
  $paddingRight?: SpaceProp;
  $paddingBottom?: SpaceProp;
  $paddingLeft?: SpaceProp;
  $paddingY?: SpaceProp;
  $paddingX?: SpaceProp;
}

export type FullContainerProps = BaseProps & ContainerProps;

const propNames: Partial<Record<keyof ContainerProps, string>> = {
  $margin: 'margin',
  $marginTop: 'margin-top',
  $marginRight: 'margin-right',
  $marginBottom: 'margin-bottom',
  $marginLeft: 'margin-left',
  $padding: 'padding',
  $paddingTop: 'padding-top',
  $paddingRight: 'padding-right',
  $paddingBottom: 'padding-bottom',
  $paddingLeft: 'padding-left',
};

const getSpacingCss = (props: ContainerProps): RuleSet<object> | null | undefined => {
  if (!props) {
    return null;
  }

  if (props.$paddingX) {
    props.$paddingLeft = props.$paddingLeft ?? props.$paddingX;
    props.$paddingRight = props.$paddingRight ?? props.$paddingX;
  }

  if (props.$paddingY) {
    props.$paddingTop = props.$paddingTop ?? props.$paddingY;
    props.$paddingBottom = props.$paddingBottom ?? props.$paddingY;
  }

  if (props.$marginX) {
    props.$marginLeft = props.$marginLeft ?? props.$marginX;
    props.$marginRight = props.$marginRight ?? props.$marginX;
  }

  if (props.$marginY) {
    props.$marginTop = props.$marginTop ?? props.$marginY;
    props.$marginBottom = props.$marginBottom ?? props.$marginY;
  }

  return Object.keys(props)
    .filter((k) => Object.keys(propNames).includes(k))
    .map((k) => {
      const propKey = k as keyof ContainerProps;
      const propName = propNames[propKey];
      const space = props[propKey];

      if (space) {
        if (typeof space !== 'object') {
          const value = spaces[space];
          return css`
            ${propName && `${propName}: ${value}px;`}
          `;
        } else {
          return Object.keys(breakpoints).reduce((styles: RuleSet<object>, k) => {
            const breakpointKey = k as BreakpointKeys;
            const currentKey = space[breakpointKey];

            if (currentKey !== undefined) {
              const minWidth = breakpoints[breakpointKey];
              const value = spaces[currentKey];

              styles.push(css`
                @media (min-width: ${minWidth}px) {
                  ${propName && `${propName}: ${value}px;`}
                }
              `);
            }

            return styles;
          }, []);
        }
      }
    });
};

const StyledContainer = styled.div<ContainerProps & BaseProps>`
  position: relative;
  box-sizing: border-box;
  ${({ $flexX }) =>
    $flexX &&
    css`
      display: flex;
      flex-direction: row;
    `}
  ${({ $flexY }) =>
    $flexY &&
    css`
      display: flex;
      flex-direction: column;
    `}
  ${({ $flexGrow }) =>
    $flexGrow &&
    css`
      flex: 1;
    `}
  ${getSpacingCss}
  ${({ $bg, $bgOpacity }) => {
    if ($bg) {
      const bgColor = Colors.List[$bg];

      return css`
        background-color: ${$bgOpacity ? 'rgba(' + hexToRgb(bgColor) + ', ' + $bgOpacity + ');' : bgColor + ';'};
      `;
    }
  }}
  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      height: 100%;
    `}
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth === 'screen' ? '100vw' : `${Sizes.maxWidth}px`};
      width: 100%;
    `}
  ${({ $centered }) =>
    $centered &&
    css`
      margin: 0 auto;
    `}
  ${({ $overflowHidden }) =>
    $overflowHidden &&
    css`
      overflow: hidden;
    `}
  ${({ $pageContainer }) =>
    $pageContainer &&
    css`
      margin-top: ${HEADER_HEIGHT}px;
      height: auto;
      z-index: 8;
    `}

  iframe {
    margin: 0 -1rem -0.5rem;
    width: calc(100% + 2rem);
  }
`;

export const Container = forwardRef<HTMLDivElement, ContainerProps & BaseProps>(
  ({ children, $as = 'div', ...props }, ref): ReactElement => {
    return (
      <StyledContainer
        data-cy={props?.$dataCy ?? 'Container'}
        aria-label={props?.$ariaLabel}
        as={$as}
        {...props}
        ref={ref}
      >
        {children}
      </StyledContainer>
    );
  }
);

Container.displayName = 'Container';
