'use client';

import React, { PropsWithChildren, ReactNode, forwardRef } from 'react';

import styled, { RuleSet, css } from 'styled-components';

import { BreakpointKeys, GridBreakpoints, OrderProps, SpaceProp, breakpoints, spaces } from 'styles/constants';

export interface GridProps {
  $gap?: SpaceProp;
  $rowGap?: SpaceProp;
  $columnGap?: SpaceProp;
  $fullHeight?: boolean;
  $fullWidth?: boolean;
}

const getGapCss = (gap: SpaceProp, propName: string) => {
  if (gap === undefined) {
    return null;
  }

  if (typeof gap !== 'object') {
    return css`
      ${propName && `${propName}: ${spaces[gap]}px;`}
    `;
  }

  return Object.keys(breakpoints).reduce((styles: RuleSet<object>, key) => {
    const breakpointKey = key as BreakpointKeys;
    const currentKey = gap[breakpointKey];

    if (currentKey !== undefined) {
      const value = spaces[currentKey];
      const breakpoint = breakpoints[breakpointKey];

      styles.push(css`
        @media (min-width: ${breakpoint}px) {
          ${propName && `${propName}: ${value}px;`}
        }
      `);
    }

    return styles;
  }, []);
};

const GridStyled = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      height: 100%;
    `}
  ${({ $gap }) => $gap !== undefined && getGapCss($gap, 'gap')}
  ${({ $rowGap }) => $rowGap !== undefined && getGapCss($rowGap, 'row-gap')}
  @media screen and (min-width: ${breakpoints.sm}px) {
    ${({ $columnGap }) => $columnGap !== undefined && getGapCss($columnGap, 'column-gap')}
  }
`;

const Grid = ({ children, ...props }: GridProps & PropsWithChildren) => {
  return (
    <GridStyled
      data-cy="Grid"
      {...props}
    >
      {children}
    </GridStyled>
  );
};

export interface ColumnProps {
  children?: ReactNode;
  $colStart?: Partial<GridBreakpoints>;
  $span?: Partial<GridBreakpoints>;
  $relative?: boolean;
  $order?: Partial<OrderProps>;
  $verticalAlign?: 'start' | 'center' | 'end';
  $horizontalAlign?: 'start' | 'center' | 'end';
}

const ColumnStyled = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  ${({ $relative }) =>
    $relative &&
    css`
      position: relative;
    `}
  ${({ $verticalAlign }) =>
    $verticalAlign &&
    css`
      justify-content: ${$verticalAlign};
    `}
  ${({ $horizontalAlign }) =>
    $horizontalAlign &&
    css`
      align-items: ${$horizontalAlign};
    `}
  ${({ $colStart, $span, $order }) => {
    let lastSpan: number | undefined;
    let lastColStart: number | undefined;
    let curOrder: RuleSet<object> | undefined;

    return Object.keys(breakpoints).reduce((styles: RuleSet<object>, key) => {
      const breakpointKey = key as BreakpointKeys;

      let curValues = [];

      if ($colStart) {
        const curColStart = $colStart[breakpointKey];
        const colStartValue = curColStart ?? lastColStart;

        if (colStartValue) {
          curValues.push('col-start ' + colStartValue);
        }

        if (curColStart) {
          lastColStart = curColStart;
        }
      }

      if ($span) {
        const curSpan = $span[breakpointKey];
        const spanValue = curSpan ?? lastSpan;

        if (spanValue) {
          curValues.push('span ' + spanValue);
        }

        if (curSpan) {
          lastSpan = curSpan;
        }
        styles.push(
          css`
            @media (min-width: ${breakpoints[breakpointKey]}px) {
              display: ${$span![breakpointKey] === 0 ? 'none' : 'flex'};
              grid-column: ${curValues.join(' / ')};
              ${curOrder}
            }
          `
        );
      }

      curOrder = !!$order?.[breakpointKey]
        ? css`
            order: ${$order[breakpointKey]};
          `
        : undefined;

      styles.push(
        css`
          @media (min-width: ${breakpoints[breakpointKey]}px) {
            display: ${$span![breakpointKey] === 0 ? 'none' : 'flex'};
            grid-column: ${curValues.join(' / ')};
            ${curOrder}
          }
        `
      );

      return styles;
    }, []);
  }}
`;

const Column = forwardRef<HTMLDivElement, ColumnProps>(({ children, ...props }, ref) => {
  return (
    <ColumnStyled
      {...props}
      ref={ref}
    >
      {children}
    </ColumnStyled>
  );
});

Column.displayName = 'Column';
Grid.displayName = 'Grid';

export { Grid, Column };
