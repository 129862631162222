import { BreakpointKeys, breakpoints } from './constants';
import { RuleSet, css } from 'styled-components';
import { Colors } from './enums';

export const mediaQueries = (breakpoint: BreakpointKeys, styles: RuleSet<object>): RuleSet<object>[] => {
  return [
    css`
      @media (min-width: ${breakpoints[breakpoint]}px) {
        ${styles}
      }
    `,
  ];
};

export const generateHighlight = (isActive: boolean) => {
  return css`
    ${mediaQueries(
      'lg',
      css`
        span {
          position: absolute;
          width: ${isActive ? '100%' : '0%'};
          left: 0px;
          bottom: -10px;
          height: 4px;
          z-index: -1;
          ${`background-color: ${isActive ? Colors.Yellow.base100 : 'transparent'};`}
        }

        &:focus {
          ${`background-color: ${!isActive ? Colors.Turkish.base100 : 'transparent'};`}
        }

        &:hover span {
          background-color: ${Colors.Yellow.base100};
          ${!isActive && 'animation: highlight 150ms forwards;'}
        }

        @keyframes highlight {
          0% {
            width: 0;
          }
          100% {
            width: 100%;
          }
        }
      `
    )}
  `;
};