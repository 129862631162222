export interface FlagProps {
  height?: number;
  width?: number;
}

const SvgComponent = ({ height = 18, width = 18 }: FlagProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2066_5683)">
      <path
        d="M8.67993 0.813721V16.5462"
        stroke="#001B71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.813721 8.67993H16.5462"
        stroke="#001B71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.78931 4.88232H15.5704"
        stroke="#001B71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.78931 12.4773H15.5704"
        stroke="#001B71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.67984 16.5462C11.1416 16.5462 13.1373 13.0243 13.1373 8.67994C13.1373 4.33555 11.1416 0.813721 8.67984 0.813721C6.21807 0.813721 4.22241 4.33555 4.22241 8.67994C4.22241 13.0243 6.21807 16.5462 8.67984 16.5462Z"
        stroke="#001B71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.67994 16.5462C13.0243 16.5462 16.5462 13.0243 16.5462 8.67994C16.5462 4.33555 13.0243 0.813721 8.67994 0.813721C4.33555 0.813721 0.813721 4.33555 0.813721 8.67994C0.813721 13.0243 4.33555 16.5462 8.67994 16.5462Z"
        stroke="#001B71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2066_5683">
        <rect
          width="17.3599"
          height="17.3599"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
