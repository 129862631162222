import * as React from 'react';
import { SVGProps } from 'react';
import styled from 'styled-components';
import { Colors } from 'styles/enums';

const Svg = styled.svg<SVGProps<SVGSVGElement>>`
  fill: ${Colors.Yellow.base100};

  &:hover {
    cursor: pointer;
    fill: ${Colors.Yellow.base050};
  }

  &:focus {
    fill: ${Colors.Turkish.base100};
  }

  &[disabled] {
    fill: ${Colors.Yellow.base020};
  }
`

// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
const PlayVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <Svg
    viewBox="0 0 132 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 132}
    height={props.height || 132}
    {...props}
  >
    <path
      d="M55.3021 90.4946L88.057 65.9284L55.3021 41.3622V90.4946ZM66.2204 11.3369C36.0586 11.3369 11.6289 35.7666 11.6289 65.9284C11.6289 96.0902 36.0586 120.52 66.2204 120.52C96.3822 120.52 120.812 96.0902 120.812 65.9284C120.812 35.7666 96.3822 11.3369 66.2204 11.3369ZM66.2204 109.602C42.1456 109.602 22.5472 90.0033 22.5472 65.9284C22.5472 41.8536 42.1456 22.2552 66.2204 22.2552C90.2953 22.2552 109.894 41.8536 109.894 65.9284C109.894 90.0033 90.2953 109.602 66.2204 109.602Z"
    />
  </Svg>
);
export default PlayVideoIcon;
