import * as React from 'react';
import { SVGProps } from 'react';

// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
const SvgCaret = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 256}
    height={props.height || 256}
    fill="none"
    {...props}
  >
    <path
      stroke={props.color || `#000000`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.189.937 7.856 8.34 14.523.937"
    />
  </svg>
);
export default SvgCaret;
