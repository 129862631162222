import { ReactNode } from 'react';

import styles from './styles.module.css';
import { Button } from '@aurora-components';
import * as Dialog from '@radix-ui/react-dialog';
import { CloseMenuIcon } from 'icons/components';

type DialogTriggerTypes = {
  children?: ReactNode;
};

type DialogContentTypes = {
  title?: string;
  description?: string;
  children?: ReactNode;
};

type CustomDialogTypes = {
  children: ReactNode;
};

export const DialogTrigger = ({ children }: DialogTriggerTypes) => {
  return <Dialog.Trigger asChild>{children}</Dialog.Trigger>;
};

export const DialogContent = ({ title, description, children }: DialogContentTypes) => (
  <Dialog.Portal>
    <Dialog.Overlay className={styles.DialogOverlay} />
    <Dialog.Content className={styles.DialogContent}>
      {title && <Dialog.Title className={styles.DialogTitle}>{title}</Dialog.Title>}
      {description && <Dialog.Description className={styles.DialogDescription}>{description}</Dialog.Description>}
      {children}
      <Dialog.Close asChild>
        <Button
          as="button"
          $variant="primary"
        >
          <CloseMenuIcon />
        </Button>
      </Dialog.Close>
    </Dialog.Content>
  </Dialog.Portal>
);

export const CustomDialog = ({ children }: CustomDialogTypes) => {
  return <Dialog.Root>{children}</Dialog.Root>;
};
