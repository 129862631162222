export enum FontWeight {
  Light = 300,
  Normal = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700
}

export enum FontFamily {
  Default = 'var(--LoftFont)'
}

export enum FontSize {
  XSmall = '10px',
  Small = '12px',
  Medium = '14px',
  Default = '16px',
  Big = '18px',
  Large = '20px',
  Larger = '22px',
  XLarge = '24px',
  XXLarge = '30px',
  XXXLarge = '40px'
}

export enum FontSizePx {
  h1 = '48px',
  h2 = '40px',
  h3 = '32px',
  h4 = '24px',
  h5 = '24px',
  h6 = '16px',
  body1 = '18px',
  body2 = '16px',
  body3 = '16px',
  body4 = '14px',
  body5 = '12px',
  body6 = '10px',
  body7 = '17px',
  card = '24px',
  navlink = '32px',
  footer1 = '20px',
}

export enum FontSizePxDesktop {
  h1 = '64px',
  h2 = '56px',
  h3 = '48px',
  h4 = '40px',
  h5 = '32px',
  h6 = '24px',
  body1 = '24px',
}