import { FontFamily } from '../../../enums';

export const button = {
  regular: {
    normal: {
      family: FontFamily.Default,
      size: {
        unit: 'px',
        value: '16'
      },
      weight: '600', //medium,
      'line-height': {
        unit: 'px',
        value: '28'
      }
    }
  },
};
