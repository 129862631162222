import { Colors, FontSizePx, FontWeight, FontSizePxDesktop } from 'styles/enums';
import { mediaQueries } from 'styles/helpers';
import { css } from 'styled-components';

export const headings = {
  h1: css`
    font-weight: ${FontWeight.Medium};
    font-size: ${FontSizePx.h1};

    ${mediaQueries(
      'md',
      css`
        font-size: ${FontSizePxDesktop.h1};
      `
    )}
  `,
  h2: css`
    font-weight: ${FontWeight.Medium};
    font-size: ${FontSizePx.h2};

    ${mediaQueries(
      'md',
      css`
        font-size: ${FontSizePxDesktop.h2};
      `
    )}
  `,
  h3: css`
    font-weight: ${FontWeight.Medium};
    font-size: ${FontSizePx.h3};

    ${mediaQueries(
      'md',
      css`
        font-size: ${FontSizePxDesktop.h3}; //48
      `
    )}
  `,
  h4: css`
    font-weight: ${FontWeight.Medium};
    font-size: ${FontSizePx.h4};

    ${mediaQueries(
      'md',
      css`
        font-size: ${FontSizePxDesktop.h4}; // 40
      `
    )}
  `,
  h5: css`
    font-weight: ${FontWeight.Medium};
    font-size: ${FontSizePx.h5};
    line-height: 34px;

    ${mediaQueries(
      'md',
      css`
        font-size: ${FontSizePxDesktop.h5}; // 32
        line-height: 42px;
      `
    )}
  `,
  h6: css`
    font-weight: ${FontWeight.Medium};
    font-size: ${FontSizePx.h6};
  `,
};

export const body = {
  p: css`
    color: ${Colors.UI.base100};
    font-weight: ${FontWeight.Light};
    font-size: ${FontSizePx.body1};
    line-height: 28px;

    ${mediaQueries(
      'md',
      css`
        font-size: ${FontSizePxDesktop.body1};
        line-height: 34px;
      `
    )}
  `,
  small: css`
    color: ${Colors.UI.base100};
    font-weight: ${FontWeight.Normal};
    font-size: ${FontSizePx.body2};
  `,
};

export const variants = {
  smallparag: css`
    color: ${Colors.UI.base100};
    font-weight: ${FontWeight.Normal};
    font-size: ${FontSizePx.body1};
    line-height: 27px;
  `,
  xsmall: css`
    color: ${Colors.UI.base100};
    font-weight: ${FontWeight.Medium};
    font-size: ${FontSizePx.body3};
  `,
  card: css`
    font-weight: ${FontWeight.Medium};
    font-size: ${FontSizePx.card};
    margin-bottom: 10px;
  `,
  cardparag: css`
    font-weight: ${FontWeight.Normal};
    font-size: ${FontSizePx.body3};
    color: ${Colors.UI.base100};
    line-height: 24px;

    ${mediaQueries(
      'md',
      css`
        font-weight: ${FontWeight.Light};
        font-size: ${FontSizePxDesktop.body1};
        line-height: 34px;
      `
    )}
  `,
  articleparag: `
    color: ${Colors.UI.base100};
    font-weight: ${FontWeight.Normal};
    font-size: ${FontSizePx.body4};
  `,
  link: css`
    font-size: ${FontSizePx.body2};
    line-height: 20px;
  `
};

export const Defaults = {
  heading: headings.h6,
  body: body.p,
}