import { css } from 'styled-components';
import { ColorTokens, EffectTokens, FontTokens, SizeTokens } from '../../tokens/';
import { sizeValuesGenerator } from '../helpers';

export const Button = {
  primary: css`
    ${sizeValuesGenerator(SizeTokens.button.normal)}
    display: flex;
    gap: ${SizeTokens.button.normal.gap.value}${SizeTokens.button.normal.gap.unit};
    font-size: ${FontTokens.button.regular.normal.size.value}${FontTokens.button.regular.normal.size.unit};
    line-height: ${FontTokens.button.regular.normal['line-height'].value}${FontTokens.button.regular.normal['line-height'].unit};
    font-weight: ${FontTokens.button.regular.normal.weight};
    background-color: ${ColorTokens.button.primary.background.default};
    color: ${ColorTokens.button.primary.text.default};
    border-radius: ${EffectTokens.button.primary.border.default.radius}${EffectTokens.button.primary.border.default.unit};
    border: none;

    &:hover {
      cursor: pointer;
      background-color: ${ColorTokens.button.primary.background.hover};
      box-shadow: 1px 3px 5px 0px ${ColorTokens.button.primary.shadow.hover};
    }

    &:focus {
      background-color: ${ColorTokens.button.primary.background.focus};
    }

    &[disabled] {
      background-color: ${ColorTokens.button.primary.background.disabled};
      color: ${ColorTokens.button.primary.text.disabled};
    }

  `,
  outline: css`
    ${sizeValuesGenerator(SizeTokens.button.normal)}
    display: flex;
    gap: ${SizeTokens.button.normal.gap.value}${SizeTokens.button.normal.gap.unit};
    font-size: ${FontTokens.button.regular.normal.size.value}${FontTokens.button.regular.normal.size.unit};
    line-height: ${FontTokens.button.regular.normal['line-height'].value}${FontTokens.button.regular.normal['line-height'].unit};
    font-weight: ${FontTokens.button.regular.normal.weight};
    background-color: ${ColorTokens.button.outline.background.default};
    color: ${ColorTokens.button.outline.text.default};
    border-radius: ${EffectTokens.button.primary.border.default.radius}${EffectTokens.button.primary.border.default.unit};
    border: ${EffectTokens.button.primary.border.default.width}${EffectTokens.button.primary.border.default.unit} solid ${ColorTokens.button.outline.border.default};
    
    &:hover {
      cursor: pointer;
      box-shadow: 0px 4px 20px 0px ${ColorTokens.button.outline.shadow.hover};
      background-color: ${ColorTokens.button.outline.background.hover};
    }

    &:focus {
      background-color: ${ColorTokens.button.outline.background.focus};
    }

    &[disabled] {
      background-color: ${ColorTokens.button.outline.background.default};
      color: ${ColorTokens.button.outline.text.disabled};
      border-color: ${ColorTokens.button.outline.text.disabled};
    }
  `,
}
