import ReactPlayer from 'react-player/lazy';
import { VideoPlayerTypes } from './types';

export const VideoPlayer = ({ controls, autoplay, url }: VideoPlayerTypes) => {
  return (
    <ReactPlayer
      width='100%'
      height='auto'
      controls={controls}
      playing={autoplay}
      volume={0.5}
      url={url}
      style={{
        position: 'relative',
        paddingBottom: '56.25%',
      }}
    />
  )
};
