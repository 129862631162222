import * as React from 'react';
import { SVGProps } from 'react';

// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
const CloseMenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 20}
    {...props}
  >
    <line
      y1="-0.75"
      x2="24"
      y2="-0.75"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 4 1)"
      stroke="#001B71"
      strokeWidth="1.5"
    />
    <line
      x1="3.98432"
      y1="18.4697"
      x2="20.9549"
      y2="1.49911"
      stroke="#001B71"
      strokeWidth="1.5"
    />
  </svg>
);
export default CloseMenuIcon;
