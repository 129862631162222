'use client';

import { useEffect, useState } from 'react';

import { BreakpointKeys, breakpoints } from 'styles/constants';
import { Colors } from 'styles/enums';

const isDevelopmentEnvironment = process.env.NODE_ENV === 'development';

/** Detects width of browser and renders a div containing the key from breakpoints
 *  Might need refactoring
 *
 */
export const BreakpointHelper = isDevelopmentEnvironment
  ? () => {
      const [breakpointString, setBreakpointString] = useState('');

      useEffect(() => {
        const handleResize = () => {
          const breakpointString = (
            window.innerWidth < 576
              ? Object.keys(breakpoints)[0]
              : Object.keys(breakpoints).find((key) => window.innerWidth < breakpoints[key as BreakpointKeys])! || 'xxl'
          ).toString();
          setBreakpointString(breakpointString);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      return (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            zIndex: 9999,
            backgroundColor: Colors.Secondary.green.base100,
            padding: '4px 12px',
            fontSize: '24px',
            fontFamily: 'monospace',
            color: 'white',
          }}
        >
          {breakpointString}
        </div>
      );
    }
  : () => null;
