import Markdown from 'markdown-to-jsx';

import { Text, TextProps } from 'components/aurora/text/text';

export type MarkdownProps = {
  markdown: string;
  forceWrapper?: boolean;
} & TextProps;

export type MarkdownRendererProps = TextProps & MarkdownProps;

const TextComponent = ({ children, ...props }: TextProps) => <Text {...props}>{children}</Text>;

const getOverrides = (props: TextProps) => {
  return {
    h1: {
      component: TextComponent,
      props: props,
    },
    h2: {
      component: TextComponent,
      props: props,
    },
    h3: {
      component: TextComponent,
      props: props,
    },
    h4: {
      component: TextComponent,
      props: props,
    },
    h5: {
      component: TextComponent,
      props: props,
    },
    h6: {
      component: TextComponent,
      props: props,
    },
    p: {
      component: TextComponent,
      props: props,
    },
    small: {
      component: TextComponent,
      props: props,
    },
  };
};

export const MarkdownRenderer = ({ markdown, forceWrapper = true, ...rest }: MarkdownRendererProps) => {
  const overrides = getOverrides(rest);
  return (
    <Markdown
      options={{
        forceWrapper: forceWrapper,
        overrides: overrides,
        wrapper: ({ children }) => (forceWrapper ? <Text {...rest}>{children}</Text> : <>{children}</>),
      }}
    >
      {markdown}
    </Markdown>
  );
};
