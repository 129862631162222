import * as React from 'react';
import { SVGProps } from 'react';

// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
const IcelandAirLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 213 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 213}
    height={props.height || 56}
    {...props}
  >
    <path
      d="M88.852 25.6704H84.2075V39.6611H87.9516V30.3809L88.0107 30.3165L96.2264 39.6611H99.523V25.6704H95.7863V33.4656L88.852 25.6704Z"
      fill="#001B71"
    />
    <path
      d="M141.673 25.6704H137.458V39.6611H141.673V25.6704Z"
      fill="#001B71"
    />
    <path
      d="M52.415 39.6611V25.6704H56.6075V36.358H63.2765V39.6611H52.415Z"
      fill="#001B71"
    />
    <path
      d="M17.8653 25.6704H13.5786V39.6611H17.8653V25.6704Z"
      fill="#001B71"
    />
    <path
      d="M156.638 34.3461C158.527 33.6125 159.643 32.3581 159.643 30.0826C159.643 27.1734 157.315 25.668 154.249 25.668H144.912V39.6661H149.123V34.6457H152.523L155.481 39.6614H159.918L156.638 34.3461ZM153.559 31.954H149.122V28.4185H153.34C155.183 28.4185 155.677 29.2426 155.677 30.2114C155.679 31.3212 154.877 31.9568 153.559 31.9568V31.954Z"
      fill="#001B71"
    />
    <path
      d="M109.998 25.6704H102.758V39.6657H110.082C115.341 39.6657 117.82 36.6473 117.82 32.6657C117.82 28.3248 115.124 25.6704 109.998 25.6704ZM109.479 36.5717H106.959V28.7084H109.48C112.293 28.7084 113.53 29.9096 113.53 32.6573C113.529 35.2203 112.528 36.5717 109.479 36.5717Z"
      fill="#001B71"
    />
    <path
      d="M129.353 25.6704H124.603L118.376 39.6657H122.322L123.524 36.9796H130.464L131.659 39.6657H135.611L129.353 25.6704ZM124.718 34.2897L126.996 28.6748L129.273 34.2897H124.718Z"
      fill="#001B71"
    />
    <path
      d="M76.1025 25.6704H71.3526L65.1255 39.6657H69.072L70.2784 36.9796H77.2183L78.4081 39.6657H82.3601L76.1025 25.6704ZM71.4663 34.2897L73.7451 28.6748L76.023 34.2897H71.4663Z"
      fill="#001B71"
    />
    <path
      d="M36.4062 39.6611V25.6704H49.406V28.5684H40.5792V31.1276H47.0948V34.0088H40.5792V36.7015H49.455V39.6611H36.4062Z"
      fill="#001B71"
    />
    <path
      d="M31.9382 34.8637C31.1016 36.0555 29.6502 36.6062 28.0749 36.6062C25.7323 36.6062 24.255 34.8329 24.255 32.6526C24.255 30.4723 25.7342 28.727 28.0749 28.727C29.5152 28.727 31.0331 29.3234 31.9151 30.4863C31.9271 30.5022 34.1255 27.9663 34.1255 27.9663C32.5835 26.3853 30.386 25.3315 28.0749 25.3315C23.7133 25.3315 20.4546 28.615 20.4546 32.6666C20.4546 36.7182 23.7133 40.0017 28.0749 40.0017C30.4489 40.0017 32.5567 38.9433 34.0986 37.3305C34.0986 37.3333 31.9474 34.8515 31.9382 34.8637Z"
      fill="#001B71"
    />
    <path
      d="M203.374 9.33443L191.888 46.6668H159.925L192.509 9.3335L203.374 9.33443Z"
      fill="#001B71"
    />
    <path
      d="M176.531 38.6939C176.432 38.9645 176.716 39.3239 177.041 39.4527C177.419 39.6029 177.923 39.7037 178.715 39.714C179.235 39.7253 179.756 39.6972 180.272 39.63C180.272 39.63 181.289 37.5179 181.449 37.1548C179.588 37.2817 177.904 37.5991 176.971 38.202C176.775 38.3131 176.621 38.4859 176.531 38.6939Z"
      fill="white"
    />
    <path
      d="M191.057 26.6396C189.146 26.6162 188.099 26.0105 185.77 25.9797C183.181 25.948 181.214 26.8318 180.623 28.0508C180.268 28.8562 178.89 31.9829 178.89 31.9829C179.546 31.4576 180.293 31.0595 181.092 30.8088C182.955 30.2142 184.688 30.3244 186.471 30.6118C187.78 30.8246 188.497 31.085 189.682 31.0486C190.759 31.0141 191.308 30.7686 191.735 30.5194C192.28 30.2049 192.752 29.7224 193.413 28.6406C193.827 27.8594 194.687 26.0189 194.858 25.668C194.858 25.668 193.913 26.6284 191.057 26.6396Z"
      fill="white"
    />
    <path
      d="M188.926 32.1316C187.713 32.0606 186.85 31.6593 184.885 31.4857C182.219 31.2468 180.214 32.0233 178.962 32.8558C178.656 33.0817 178.468 33.1078 178.137 33.8013C177.55 35.1285 177.021 36.2382 176.585 37.2341C176.506 37.3998 176.444 37.5734 176.4 37.7521C176.619 37.5076 177.949 35.5896 184.847 36.2056C187.477 36.4529 188.81 36.34 190.158 34.7328C191.175 33.4177 192.1 31.3774 192.1 31.3774C192.1 31.3774 191.768 31.7722 190.474 32.0494C189.964 32.1448 189.443 32.1725 188.926 32.1316V32.1316Z"
      fill="white"
    />
  </svg>
);
export default IcelandAirLogo;
