import { Colors } from '../../../enums';

export const button = {
  primary: {
    background: {
      default: Colors.Yellow.base100,
      hover: Colors.Yellow.base050,
      focus: Colors.Turkish.base100,
      disabled: Colors.Yellow.base020,
    },
    text: {
      default: Colors.Primary.base100,
      disabled: Colors.Primary.base050
    },
    shadow: {
      hover: Colors.withOpacity.primary['10%']
    }
  },
  outline: {
    background: {
      default: Colors.UI.transparent,
      hover: Colors.Primary.base005,
      focus: Colors.Turkish.base100,
      disabled: Colors.Primary.base030
    },
    text: {
      default: Colors.Primary.base100,
      disabled: Colors.Primary.base030
    },
    border: {
      default: Colors.Primary.base100
    },
    shadow: {
      hover: Colors.withOpacity.primary['10%']
    }
  }
}
